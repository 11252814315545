export * from "./update-dataset-value"
export * from "./with-props-validation"

/**
 * Defer importing a def view function until it's called
 *
 * There are some limitations in webpack around dynamic expressions, and so we
 * need to have the view base be a known path like `../`
 *
 * https://webpack.js.org/guides/migrating/#dynamic-expressions
 *
 * @param {String} path Path to view module (relative to entry root)
 */

function importDeferredView(path) {
  return async (el, props) => {
    const f = await import(`../${path}/index.ts`)
    return f.default(el, props)
  }
}

function importDeferredViewTsx(path) {
  return async (el, props) => {
    const f = await import(`../${path}/index.tsx`)
    return f.default(el, props)
  }
}

function importDeferredViewJs(path) {
  return async (el, props) => {
    const f = await import(`../${path}/index.js`)
    return f.default(el, props)
  }
}

export default {
  openSingleDetail: importDeferredView("shared/open-single-detail"),
  masthead: importDeferredView("shared/masthead"),
  toggle: importDeferredView("shared/toggle"),
  hubspotForms: importDeferredView("shared/hubspot-forms"),
  testimonialCarousel: importDeferredView("shared/testimonial-carousel"),
  ribbon: importDeferredView("shared/ribbon"),
  stickyFilter: importDeferredView("shared/sticky-filter"),
  basicCarousel: importDeferredView("shared/basic-carousel"),
  adSpots: importDeferredView("shared/ad-spots"),
  copyToClipboard: importDeferredView("shared/copy-to-clipboard"),
  dialogModal: importDeferredView("shared/dialog-modal"),
  dropdown: importDeferredView("shared/dropdown"),
  filters: importDeferredView("shared/filters"),
  toggleFilter: importDeferredView("shared/toggle-filter"),
  lazyLoad: importDeferredView("shared/lazy-load"),
  localizedDateTime: importDeferredView("shared/localized-date-time"),
  logoSetRows: importDeferredView("shared/logo-set-rows"),
  panelModal: importDeferredView("shared/panel-modal"),
  salesToggle: importDeferredView("shared/sales-toggle"),
  tableOfContents: importDeferredView("shared/table-of-contents"),
  tabs: importDeferredView("shared/tabs"),
  videoModal: importDeferredView("shared/video-modal"),
  analyticsPageView: importDeferredView("shared/analytics/page-view"),
  analyticsEvent: importDeferredView("shared/analytics/event"),
  analyticsClickEvent: importDeferredView("shared/analytics/click-event"),
  analyticsGlobalEvents: importDeferredView("shared/analytics/global-events"),
  analyticsSegmentLoader: importDeferredView("shared/analytics/segment-loader"),
  analyticsTrackLink: importDeferredView("shared/analytics/track-link"),
  analyticsIdentifyAndTrackLink: importDeferredView(
    "shared/analytics/identify-and-track-link",
  ),
  articlesSearch: importDeferredViewTsx("templates/articles-search"),
  textSnippetShare: importDeferredView("templates/text-snippet-share"),
  companyValuesList: importDeferredView("templates/company-values-list"),
  backgroundVideoControls: importDeferredView(
    "templates/background-video-controls",
  ),
  componentLibraryMasthead: importDeferredView(
    "templates/component-library-masthead",
  ),
  packageFeatureToggle: importDeferredView(
    "templates/pricing/package-feature-toggle",
  ),
  currencyToggle: importDeferredView("templates/pricing/currency-toggle"),
  hsHiddenFieldsAssignment: importDeferredView(
    "templates/pricing/hs-hidden-fields-assignment",
  ),
  podcastCloseTranscript: importDeferredView(
    "templates/podcast-close-transcript",
  ),
  podcastSubscribeToggle: importDeferredView(
    "templates/podcast-subscribe-toggle",
  ),
  productBlock: importDeferredView("templates/product-block"),
  ropiCalculator: importDeferredViewTsx("templates/ropi-calculator"),
  careersListing: importDeferredViewTsx("templates/careers-listing"),
  partnersMarketplaceListing: importDeferredViewTsx(
    "templates/partners-marketplace/partners-listing",
  ),
  partnersHiddenFormFields: importDeferredView(
    "templates/partners-marketplace/partners-form",
  ),
  analyticsWistiaEvents: importDeferredView("analytics/wistia-events"),
  storyAnimationOnScroll: importDeferredView(
    "templates/campaigns/research_guide_y22_q3/story-animation-on-scroll",
  ),
  mainAnimations: importDeferredView(
    "templates/campaigns/research_guide_y22_q3/main-animations",
  ),
  companyDirections: importDeferredViewTsx(
    "templates/campaigns/research_guide_y22_q3/company-directions",
  ),
  typeOfCompanyQuiz: importDeferredViewTsx(
    "templates/campaigns/research_guide_y22_q3/type-of-company-quiz",
  ),
  progressBarAnimation: importDeferredView(
    "templates/campaigns/ex-map/progress-bar-animation",
  ),
  exMapPills: importDeferredView("templates/campaigns/ex-map/pills"),
  exMapCardFlip: importDeferredView("templates/campaigns/ex-map/card-flip"),
  exMapAnimations: importDeferredView(
    "templates/campaigns/ex-map/ex-map-animations",
  ),
  cultureFirstGlobal: importDeferredView(
    "templates/campaigns/culture-first-global",
  ),
  survey: importDeferredViewJs("templates/campaigns/hrforhr/survey"),
  carousel: importDeferredView("shared/carousel"),
  marquee: importDeferredView("shared/marquee"),
  caseStudyCarousel: importDeferredView("shared/case-study-carousel"),
  counter: importDeferredView("shared/counter"),
  toggleReveal: importDeferredView("shared/toggle-reveal"),
}
